<template>
  <div>
    <breadcrumb :items="breadcrumbs"></breadcrumb>
    <v-card
      color="rgba(0,0,0,0)"
      dark
      max-width="500"
      class="mx-auto py-0 mt-10"
    >
      <span class="text-h6 third--text">{{ $t("promotions.add.title") }}</span>
    </v-card>
    <v-card class="mx-auto mt-4" max-width="500" dark color="fifth">
      <v-divider></v-divider>
      <v-card-text class="px-5">
        <promotion-form></promotion-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PromotionForm from './form';
import Breadcrumb from "../commons/breadcrumb.vue";
export default {
  created() {
    this.setBreadcrumbs();
  },
  data: () => ({
  }),
  methods: {
    setBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("promotions.title"),
          to: this.$i18n.getRouteLink("promotion"),
          disabled: false,
          exact: true,
        },
        {
          text: this.$t("promotions.add.title"),
          disabled: true,
        },
      ];
    },
  },
  components: {
    Breadcrumb,
    PromotionForm
  }
}
</script>