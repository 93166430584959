<template>
  <div>
    <promotion-add></promotion-add>
  </div>
</template>

<script>
import PromotionAdd from "./../../components/promotions/add";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("promotions.add.title")
    };
  },
  components: {
    PromotionAdd
  }
};
</script>